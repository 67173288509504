<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th sort-key="code">Code</vs-th>
        <!-- <vs-th sort-key="name">Name</vs-th> -->
        <vs-th sort-key="description">Description</vs-th>
        <vs-th sort-key="survey_catalogs">Surve Catalog</vs-th>
        <vs-th sort-key="valid_from">Valid From</vs-th>
        <vs-th sort-key="valid_to">Valid To</vs-th>
        <vs-th sort-key="territory">Territory</vs-th>
        <vs-th sort-key="sales">Salesman</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                @click.stop="handleEdit(data[indextr].id)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
              />
              <vs-button
                @click.stop="handleDelete(data[indextr].id)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                title="Delete"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <!-- <vs-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </vs-td> -->
          <vs-td :data="data[indextr].description">
            {{ data[indextr].description }}
          </vs-td>
          <vs-td :data="data[indextr].survey_catalogs">
            <div v-html="data[indextr].survey_catalogs"></div>
          </vs-td>
          <vs-td :data="data[indextr].valid_from">
            {{ data[indextr].valid_from }}
          </vs-td>
          <vs-td :data="data[indextr].valid_to">
            {{ data[indextr].valid_to }}
          </vs-td>
          <vs-td :data="data[indextr].territory">
            <div
              v-if="data[indextr].territory_total > 0"
              v-html="data[indextr].territory"
            ></div>
            <div v-else>All</div>
          </vs-td>
          <vs-td :data="data[indextr].sales">
            <div
              v-if="data[indextr].sales_total > 0"
              v-html="data[indextr].sales"
            ></div>
            <div v-else>All</div>
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.territoryID,
            zone_id: this.zoneID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId + "/delete")
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.$router.push({
        name: "survey-allocation-edit",
        params: { id: id },
      });
    },
  },
  watch: {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
